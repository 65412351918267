import { template as template_6fb570eb061842c39a1faea3b21da4ef } from "@ember/template-compiler";
const SidebarSectionMessage = template_6fb570eb061842c39a1faea3b21da4ef(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
