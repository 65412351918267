import { template as template_222eac2a6b5e448aaa4d110fbc8606b9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_222eac2a6b5e448aaa4d110fbc8606b9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
