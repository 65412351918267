import { template as template_cc89d95183c54fa69ad2dc1eb086261d } from "@ember/template-compiler";
const FKLabel = template_cc89d95183c54fa69ad2dc1eb086261d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
